const Disclaimer = () => {
  return (
    <div className="flex justify-center mb-[1em]">
      <p className="font-thin text-white text-sm ">
        The fuel prices are expressed in Petrodollars relative to the world oil
        trade standards.
      </p>
    </div>
  );
};

export default Disclaimer;
