const Disclaimer2 = () => {
  return (
    <div className="flex justify-center md:mb-[-2.5em] mb-[2em] ">
      <p className="font-thin text-white text-sm text-center">
        Prices are converted from USD to other currencies, <br></br>
        as the USD is base currency for oil trading
      </p>
    </div>
  );
};

export default Disclaimer2;
